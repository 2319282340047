import React, { useState, useRef, useEffect } from "react";
import "./admininrwithdraw.scss";
import { Card } from "primereact/card";
import axios from "axios";
import { Button } from "primereact/button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
export const AdminInrWithdraw = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  const [withdraw, setWithdraw] = useState({});
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [dataBank, setDataBank] = useState({});
  const [rejectId, setRejectId] = useState("");
  const [reason, setReason] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(url + "/admin/getinrwithdrawlist",{tp:"BANK"}, {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.respwithlist);
        setWithdraw(resp.data.withdraws)
      }
    };
    fetchData();
  }, []);

  const getBankDetails = async (memId) => {
    const resp = await axios.post(
      url + "/admin/getbankdetails",
      { memId },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setVisible(true);
      setDataBank(resp.data.bankData);
    }
  };
  const approve = async (tranid) => {
    const resp = await axios.post(
      url + "/admin/approveinrwithdraw",
      { tranid, trtype:"INR" },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      window.location.reload(true);
    }
  };
  const reject = (tranid) =>{
    setRejectId(tranid)
    setShow(true)
  }
  const confirmReject = async () =>{
    const resp = await axios.post(
      url + "/admin/rejectwithdraw",
      { rejectId, reason },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      window.location.reload(true);
    }
  }
  const footerContent = (
    <div>
        <Button label="Confirm" icon="pi pi-check" onClick={() => confirmReject()} autoFocus />
    </div>
);
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }

  return (
    <div className="admininrwithdraw">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12 right">
          <Card title="INR Withdraw">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Member Id</TableCell>
                    <TableCell align="center">Bank Details</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Approv</TableCell>
                    <TableCell align="center">Reject</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.memberid}</TableCell>
                      <TableCell align="center">
                        <Button onClick={() => getBankDetails(row.memberid)}>
                          Bank Details
                        </Button>
                      </TableCell>
                      <TableCell align="center">{row.commission}</TableCell>
                      <TableCell align="center">
                      <Button
                          label="Approve"
                          onClick={() => approve(row._id)}
                        />
                      </TableCell>
                      <TableCell align="center">
                      <Button
                          label="Reject"
                          onClick={() => reject(row._id)}
                          severity="danger"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Card style={{marginTop:5}}>
              <div className="actbet">Approved Withdraw</div>
              <DataTable value={withdraw} tableStyle={{ minWidth: '50rem' }}>
                <Column field="tran_date" header="Date"></Column>
                <Column field="memberid" header="Memberid"></Column>
                <Column field="withdraw_type" header="Type"></Column>
                <Column field="amount" header="Amount"></Column>
            </DataTable>
            </Card>
        </div>
      </div>
      <Dialog
        visible={visible}
        modal
        header="Bank Details"
        style={{ width: "50rem" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="data">
          <p className="m-0">User Name</p>
          <p className="m-0">{dataBank.username}</p>
        </div>
        <div className="data">
          <p className="m-0">Bank Name</p>
          <p className="m-0">{dataBank.bankname}</p>
        </div>
        <div className="data">
          <p className="m-0">Account</p>
          <p className="m-0">{dataBank.accno}</p>
        </div>
        <div className="data">
          <p className="m-0">Branch</p>
          <p className="m-0">{dataBank.branch}</p>
        </div>
        <div className="data">
          <p className="m-0">IFSC</p>
          <p className="m-0">{dataBank.ifsc}</p>
        </div>
      </Dialog>
      <Dialog
        visible={show}
        modal
        header="Reject Reason"
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          if (!show) return;
          setShow(false);
        }}
      >
        <div>
          <InputText placeholder="Reject Reason" value={reason} onChange={e => setReason(e.target.value)} style={{width:'100%'}} />
        </div>
      </Dialog>
    </div>
  );
};
