// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/bgtry.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-light .userslist {
  min-height: 100vh;
  background-color: rgb(238, 244, 247);
  color: #000;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 60px;
}
.theme-dark .userslist {
  min-height: 100vh;
  background-color: #1e3948;
  color: whitesmoke;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 60px;
}`, "",{"version":3,"sources":["webpack://./src/style.scss","webpack://./src/pages/admin/userslist/userslist.scss"],"names":[],"mappings":"AA0BI;ECvBA,iBAAA;EACA,oCAAA;EACA,WAAA;EACA,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,mBAAA;AADJ;ADkBI;ECvBA,iBAAA;EACA,yBAAA;EACA,iBAAA;EACA,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,mBAAA;AAQJ","sourcesContent":["$themes: (\n  light: (\n    navDefault: #fb6562,\n    bg:rgb(238, 244, 247),\n    footerBg:rgb(168, 210, 233),\n    cardLight:rgb(238, 244, 247),\n    semiTrans:rgba(255, 255, 255, 0.1),\n    textColor: #000,\n    textContra:#512727,\n    linkText:rgb(61, 61, 138),\n    menuBg:rgb(214, 214, 244)\n  ),\n  dark: (\n    navDefault: #fc8701c7,\n    bg: #1e3948,\n    footerBg: #142b37,\n    cardLight:#1c3645,\n    semiTrans:rgba(231, 169, 169, 0.193),\n    textColor: whitesmoke,\n    textContra:#f5ff84,\n    linkText:aqua,\n    menuBg:#132d3a\n  ),\n);\n@mixin themify($themes) {\n  @each $theme, $map in $themes {\n    .theme-#{$theme} & {\n      $theme-map: () !global;\n      @each $key, $submap in $map {\n        $value: map-get(map-get($themes, $theme), \"#{$key}\");\n        $theme-map: map-merge(\n          $theme-map,\n          (\n            $key: $value,\n          )\n        ) !global;\n      }\n      @content;\n      $theme-map: null !global;\n    }\n  }\n}\n\n@function themed($key) {\n  @return map-get($theme-map, $key);\n}\n\n@mixin mobile {\n  @media (max-width: 480px) {\n    @content;\n  }\n}\n@mixin tablet {\n  @media (max-width: 960px) {\n    @content;\n  }\n}","@import \"../../../style.scss\";\n.userslist {\n  @include themify($themes) {\n    min-height: 100vh;\n    background-color: themed('bg');\n    color: themed('textColor');\n    background-image: url(\"../../../images/bgtry.jpg\");\n    background-repeat: no-repeat;\n    background-size: cover;\n    padding: 100px 60px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
