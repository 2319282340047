import React, { useState, useRef, useEffect } from "react";
import "./profile.scss";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Form, FormikProvider, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
export const Profile = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [isProfile, setisProfile] = useState(0);
  const [profile, setProfile] = useState({})
  const actmemid = localStorage.getItem("damanmemid");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getUserProfile",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setProfile(resp.data.profile);
        setisProfile(resp.data.found)
        if(resp.data.found===1){
            formik.setFieldValue("username",resp.data.profile.username)
            formik.setFieldValue("bankname",resp.data.profile.bankname)
            formik.setFieldValue("accountno",resp.data.profile.accno)
            formik.setFieldValue("branch",resp.data.profile.branch)
            formik.setFieldValue("ifsc",resp.data.profile.ifsc)
            formik.setFieldValue("upiid",resp.data.profile.upiid)
        }
        
      }
    };
    fetchData();
  }, []);


  const validationSchema = Yup.object({
    username: Yup.string().required("User Name can not be blank"),
    bankname: Yup.string().required("Bank Name Can NO Be Blank"),
    accountno: Yup.number("Invalid Account Number").required(
      "Field can not be empty."
    ),
    caccountno: Yup.mixed().oneOf(
      [Yup.ref("accountno"), null],
      "Account Number not matched"
    ),
    branch: Yup.string().required("Field can not be empty."),
    ifsc: Yup.string().required("Field can not be empty."),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      bankname: "",
      accountno: "",
      caccountno: "",
      branch: "",
      ifsc:"",
      upiid:""
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/user/setuserprofile",
        {
          actmemid,
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
      } else {
        setButtonDisabled(0);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="profile">
        <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-5 left">
          <div className="content">
            <Card className="semiTrans">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    {/* <img src="/images/qrcode.webp" width="150" alt="" /> */}
                    <h3>USER PROFILE</h3>
                    <div className="component">
                      <InputText
                        type="text"
                        name="username"
                        placeholder="Full Name"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.username}
                      />
                      {getFormErrorMessage("username")}
                    </div>
                    <div className="component">
                      <InputText
                        type="text"
                        name="bankname"
                        placeholder="Bank Name"
                        onChange={formik.handleChange}
                        value={formik.values.bankname}
                      />
                      {getFormErrorMessage("bankname")}
                    </div>
                    <div className="component">
                      <InputText
                        type="number"
                        name="accountno"
                        placeholder="Account Number"
                        onChange={formik.handleChange}
                        value={formik.values.accountno}
                      />
                      {getFormErrorMessage("accountno")}
                    </div>
                    <div className="component">
                      <InputText
                        type="number"
                        name="caccountno"
                        placeholder="Confirm Account Number"
                        onChange={formik.handleChange}
                        value={formik.values.caccountno}
                      />
                      {getFormErrorMessage("caccountno")}
                    </div>
                    <div className="component">
                      <InputText
                        type="text"
                        name="branch"
                        placeholder="Branch"
                        onChange={formik.handleChange}
                        value={formik.values.branch}
                      />
                      {getFormErrorMessage("branch")}
                    </div>
                    <div className="component">
                      <InputText
                        type="text"
                        name="ifsc"
                        placeholder="IFSC CODE"
                        onChange={formik.handleChange}
                        value={formik.values.ifsc}
                      />
                      {getFormErrorMessage("ifsc")}
                    </div>
                    <div className="component">
                      <InputText
                        type="text"
                        name="upiid"
                        placeholder="UPI ID"
                        onChange={formik.handleChange}
                        value={formik.values.upiid}
                      />
                      {getFormErrorMessage("upiid")}
                    </div>
                    {isProfile===0?
                    <div className="component">
                    {isButtonDisabled === 0 ? (
                      <Button type="submit" label="Submit" size="large" />
                    ) : (
                      <>
                        {isButtonDisabled === 1 ? (
                          <Button
                            type="button"
                            disabled
                            label="Submitting"
                            size="large"
                          />
                        ) : (
                          <Button
                            type="button"
                            label="Submitted"
                            size="large"
                          />
                        )}
                      </>
                    )}
                  </div>
                    :
                    <Button type="button" disabled label="Submit" size="large" />
                    }
                    
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
