import React, { useState, useRef, useEffect } from "react";
import "./manageautobid.scss";
import { Toast } from "primereact/toast";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import axios from "axios";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
export const ManageAutoBid = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actmemid = localStorage.getItem("damanmemid");
  const [data, setData] = useState({});
  const [data1, setData1] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getUserAutoBid",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.userunclaimedbid);
        setData1(resp.data.userclaimedbid);
      }
    };
    fetchData();
  }, []);
  const ClaimRegBet = async (uid) => {
    const resp = await axios.post(
      url + "/user/claimRegBid",
      {
        uid,
        actmemid,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
      const resp1 = await axios.post(
        url + "/user/getUserRegBid",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp1.status === 200) {
        setData(resp1.data.userunclaimedbid);
        setData1(resp1.data.userclaimedbid);
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
    }
  };
  const ClaimInterest = async (bidid) => {
    const resp = await axios.post(
      url + "/user/calcfdinterest",
      {
        actmemid,
        bidid,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
      const resp1 = await axios.post(
        url + "/user/getUserAutoBid",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp1.status === 200) {
        setData(resp1.data.userunclaimedbid);
        setData1(resp1.data.userclaimedbid);
      }

    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
    }
  };
  const WithdrawAutoBid = async (bidid) =>{
    const resp = await axios.post(
      url + "/user/claimFinalAutoBid",
      {
        actmemid,
        bidid,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if(resp.status===200){
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
      const resp1 = await axios.post(
        url + "/user/getUserAutoBid",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp1.status === 200) {
        setData(resp1.data.userunclaimedbid);
        setData1(resp1.data.userclaimedbid);
      }
    }else{
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
    }
    
  }
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  var rows1 = [];
  for (let i = 0; i < data1.length; i++) {
    rows1.push(data1[i]);
  }
  return (
    <div className="manageautobid">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-12 left">
            <Card className="cust-bg" title="RUNNING AUTO BET">
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Pecentage</TableCell>
                      <TableCell align="center">Lock For</TableCell>
                      <TableCell align="center">Claim Days</TableCell>
                      <TableCell align="center">Current</TableCell>
                      <TableCell align="center">Total</TableCell>
                      <TableCell align="center">Claim</TableCell>
                      <TableCell align="center">Withdraw</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {moment(row.tran_date)
                            .utcOffset(330)
                            .format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">{row.amount}</TableCell>
                        <TableCell align="center">{row.rate}</TableCell>
                        <TableCell align="center">{row.for_days}</TableCell>
                        <TableCell align="center">{row.claim_days}</TableCell>
                        <TableCell align="center">
                          {row.till_date_amount.toFixed(2)}
                        </TableCell>
                        <TableCell align="center">
                          {row.final_amount.toFixed(2)}
                        </TableCell>
                        <TableCell align="center">
                          {row.for_days === row.claim_days ? (
                            <Button
                              label="Claim"
                              size="small"
                              outlined
                              disabled
                            />
                          ) : (
                            <Button
                              label="Claim"
                              size="small"
                              outlined
                              onClick={() => ClaimInterest(row._id.toString())}
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.for_days === row.claim_days ? (
                            <Button
                              label="Withdraw"
                              size="small"
                              outlined
                              onClick={() => WithdrawAutoBid(row._id.toString())}
                            />
                          ) : (
                            <Button
                              label="Withdraw"
                              size="small"
                              outlined
                              disabled
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </div>
        </div>
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-12 left">
            <Card className="cust-bg" title="COMPLETED BET">
              <TableContainer>
                <Table size="small">
                  <TableHead>
                  <TableRow>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Pecentage</TableCell>
                      <TableCell align="center">Lock For</TableCell>
                      <TableCell align="center">Claim Days</TableCell>
                      <TableCell align="center">Current</TableCell>
                      <TableCell align="center">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {rows1.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {moment(row.tran_date)
                            .utcOffset(330)
                            .format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">{row.amount}</TableCell>
                        <TableCell align="center">{row.rate}</TableCell>
                        <TableCell align="center">{row.for_days}</TableCell>
                        <TableCell align="center">{row.claim_days}</TableCell>
                        <TableCell align="center">
                          {row.till_date_amount.toFixed(2)}
                        </TableCell>
                        <TableCell align="center">
                          {row.final_amount.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
