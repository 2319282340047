import React, { useState, useRef, useEffect } from "react";
import "./wingobet.scss";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import clock from "../../../images/clock.png";
import clockred from "../../../images/clockred.png";
export const WingoBet = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actmemid = localStorage.getItem("damanmemid");
  const baseurl = process.env.REACT_APP_URL;
  const [activeGame, setActiveGame] = useState(1);
  const [activeChartButton, setActiveChartButton] = useState(0);

  const setActGame = (gameId) => {
    setActiveGame(gameId);
  };
  const setBid = async (game, index, lebel, pmcolor, seccolor) => {
    // setSelectedGame(lebel);
    // setPrimeColor(pmcolor);
    // setSecondColor(seccolor);
    // setVisible(true);
  };
  const setActChart = async (actChart, game, index, lebel, pmcolor, seccolor) => {
    setActiveChartButton(actChart)
    // setSelectedGame(lebel);
    // setPrimeColor(pmcolor);
    // setSecondColor(seccolor);
    // setVisible(true);
  };
  return (
    <div className="wingobet">
      <div className="wreper">
        <div className="topmenu">
          <div className="bac">
            <i className="pi pi-angle-left" style={{ fontSize: "1.4rem" }} />
          </div>
          <div className="logo">
            <img src={`${baseurl}/images/damanlogo.png`} alt="logo" />
          </div>
          <div className="help">
            <div className="helpicon">
              <img src={`${baseurl}/images/help.png`} alt="logo" />
            </div>
            <div className="sound">
              <img src={`${baseurl}/images/voice.png`} alt="logo" />
            </div>
          </div>
        </div>
        <div className="platform">
          <div className="top">
            <div className="walletfrm">
              <div className="wallet">
                <div className="wamt">
                  <div className="mainbalance">₹4.62</div>
                </div>
                <div className="wl">
                  <i className="pi pi-wallet"></i>
                  <div className="wbll">Wallet Balance</div>
                </div>
                <div className="buttons">
                  <div className="btnwithdraw">Withdraw</div>
                  <div className="btndeposit">Deposit</div>
                </div>
              </div>
            </div>
            <div className="noticefrm">
              <div className="notice">
                <div className="nicon">
                  <VolumeUpIcon style={{ fontSize: "20px" }} />
                </div>
                <div className="nmeg"></div>
                <div className="nbtn">
                  <LocalFireDepartmentIcon style={{ fontSize: "18px" }} />
                  <div>Detail</div>
                </div>
              </div>
            </div>
            <div className="activegame">
              <div className="game">
                {activeGame === 1 ? (
                  <div className="winactive">
                    <div className="winlogo">
                      <img src={clockred} alt="" />
                    </div>
                    <div className="wingo">Win Go</div>
                    <div className="wingotime">30s</div>
                  </div>
                ) : (
                  <div className="win" onClick={() => setActGame(1)}>
                    <div className="winlogo">
                      <img src={clock} alt="" />
                    </div>
                    <div className="wingo">Win Go</div>
                    <div className="wingotime">30s</div>
                  </div>
                )}
                {activeGame === 2 ? (
                  <div className="winactive">
                    <div className="winlogo">
                      <img src={clockred} alt="" />
                    </div>
                    <div className="wingo">Win Go</div>
                    <div className="wingotime">1 Min</div>
                  </div>
                ) : (
                  <div className="win" onClick={() => setActGame(2)}>
                    <div className="winlogo">
                      <img src={clock} alt="" />
                    </div>
                    <div className="wingo">Win Go</div>
                    <div className="wingotime">1 Min</div>
                  </div>
                )}
                {activeGame === 3 ? (
                  <div className="winactive">
                    <div className="winlogo">
                      <img src={clockred} alt="" />
                    </div>
                    <div className="wingo">Win Go</div>
                    <div className="wingotime">3 Min</div>
                  </div>
                ) : (
                  <div className="win" onClick={() => setActGame(3)}>
                    <div className="winlogo">
                      <img src={clock} alt="" />
                    </div>
                    <div className="wingo">Win Go</div>
                    <div className="wingotime">3 Min</div>
                  </div>
                )}
                {activeGame === 4 ? (
                  <div className="winactive">
                    <div className="winlogo">
                      <img src={clockred} alt="" />
                    </div>
                    <div className="wingo">Win Go</div>
                    <div className="wingotime">5 Min</div>
                  </div>
                ) : (
                  <div className="win" onClick={() => setActGame(4)}>
                    <div className="winlogo">
                      <img src={clock} alt="" />
                    </div>
                    <div className="wingo">Win Go</div>
                    <div className="wingotime">5 Min</div>
                  </div>
                )}
              </div>
            </div>
            <div className="timerdiv">
              <div className="timer">
                <div className="timerleft">
                  <div className="htp">
                    <i className="pi pi-clock"></i>
                    <div>How To Play</div>
                  </div>
                  <div className="wactg">Win Go 5Min</div>
                  <div className="trndimg">IMAGES</div>
                </div>
                <div className="timerright">
                  <div className="tmremain">Time Remaining</div>
                  <div className="acttimer">Act Timer</div>
                  <div className="actgameid">{"actGame.gameid"}</div>
                </div>
              </div>
            </div>
            <div className="stage">
              <div className="gamestage">
                <div className="colorbtn">
                  <div className="btngreen">Green</div>
                  <div className="btnviolet">Violet</div>
                  <div className="btnred">Red</div>
                </div>
                <div className="numbers">
                  <div className="nums">
                    <div className="smlnumbers">
                      <img
                        src="/images/num0.png"
                        alt="num0"
                        onClick={() =>
                          setBid("number", 0, "NUMBER 0", "red", "violet")
                        }
                      />
                      <img
                        src="/images/num1.png"
                        alt="num1"
                        onClick={() =>
                          setBid("number", 1, "NUMBER 1", "#00ec24", "#FFF")
                        }
                      />
                      <img
                        src="/images/num2.png"
                        alt="num2"
                        onClick={() =>
                          setBid("number", 2, "NUMBER 2", "#990000", "#FFF")
                        }
                      />
                      <img
                        src="/images/num3.png"
                        alt="num3"
                        onClick={() =>
                          setBid("number", 3, "NUMBER 3", "#00ec24", "#FFF")
                        }
                      />
                      <img
                        src="/images/num4.png"
                        alt="num4"
                        onClick={() =>
                          setBid("number", 4, "NUMBER 4", "#990000", "#FFF")
                        }
                      />
                    </div>
                    <div className="bgnumbers">
                      <img
                        src="/images/num5.png"
                        alt="num0"
                        onClick={() =>
                          setBid("number", 5, "NUMBER 2", "green", "violet")
                        }
                      />
                      <img
                        src="/images/num6.png"
                        alt="num1"
                        onClick={() =>
                          setBid("number", 6, "NUMBER 6", "#990000", "#FFF")
                        }
                      />
                      <img
                        src="/images/num7.png"
                        alt="num2"
                        onClick={() =>
                          setBid("number", 7, "NUMBER 7", "#00ec24", "#FFF")
                        }
                      />
                      <img
                        src="/images/num8.png"
                        alt="num3"
                        onClick={() =>
                          setBid("number", 8, "NUMBER 8", "#990000", "#FFF")
                        }
                      />
                      <img
                        src="/images/num9.png"
                        alt="num4"
                        onClick={() =>
                          setBid("number", 9, "NUMBER 9", "#00ec24", "#FFF")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="rndpic">
                  <div className="rendbtn">Random</div>
                  <div className="picx">
                    <div className="x1">X1</div>
                    <div className="x5">X5</div>
                    <div className="x10">X10</div>
                    <div className="x20">X20</div>
                    <div className="x50">X50</div>
                    <div className="x100">X100</div>
                  </div>
                </div>
                <div className="bigsmallfrm">
                  <div className="big">BIG</div>
                  <div className="small">SMALL</div>
                </div>
              </div>
            </div>
            <div className="charhistory">
              {activeChartButton===0?<div className="btncharhistory hactive">Game history</div>:<div className="btncharhistory" onClick={()=>setActChart(0)}>Game history</div>}
              {activeChartButton===1?<div className="btncharhistory hactive">Chart</div>:<div className="btncharhistory" onClick={()=>setActChart(1)}>Chart</div>}
              {activeChartButton===2?<div className="btncharhistory hactive">My history</div>:<div className="btncharhistory" onClick={()=>setActChart(2)}>My history</div>}
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};
