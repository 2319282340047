import React, { useState, useRef, useEffect } from "react";
import "./wingoadminsetup.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
export const WinGoAdminSetup = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [bet, setBet] = useState(null);
  const bettype = [
    { name: "30 Seconds", seconds: "30" },
    { name: "1 Minute", seconds: "60" },
    { name: "3 Minute", seconds: "180" },
    { name: "5 Minute", seconds: "300" },
  ];
  const validationSchema = Yup.object({
    betdate: Yup.date("Invalid Email, Please Check!!!").required(
      "Field can not be empty."
    ),
  });
  const formik = useFormik({
    initialValues: {
      betdate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (bet === null) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please Select Bet Type",
        });
        return false;
      }
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/admin/setwingodate",
        {
          values,
          bet,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.error,
        });
      } else {
        setButtonDisabled(0);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="wingoadminsetup">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <Card title="SET UP WINGO">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <div className="component">
                      <Dropdown
                        value={bet}
                        onChange={(e) => setBet(e.value)}
                        options={bettype}
                        optionLabel="name"
                        placeholder="Select Bet Type"
                      />
                    </div>
                    <div className="component">
                      <div>Bet Date</div>
                      <InputText
                        type="date"
                        name="betdate"
                        placeholder="Email Address"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.betdate}
                      />
                      {getFormErrorMessage("betdate")}
                    </div>
                    <div className="component">
                      {isButtonDisabled === 0 ? (
                        <Button type="submit" label="Submit" size="large" />
                      ) : (
                        <>
                          {isButtonDisabled === 1 ? (
                            <Button
                              type="button"
                              disabled
                              label="Submitting"
                              size="large"
                            />
                          ) : (
                            <Button
                              type="button"
                              label="Submitted"
                              size="large"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
