import React, { useState, useRef, useEffect } from "react";
import "./bidlist.scss";
import axios from "axios";
import { Button, grid2Classes } from "@mui/material";
import { Divider } from "primereact/divider";
export const BidList = (props) => {
  const logedin = props.logedin;
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const baseurl = process.env.REACT_APP_URL;
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/user/getbidlist", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.gamelist);
      }
    };
    fetchData();
  }, []);

  if (!data) return false;
  var rows6 = [];
  var rows12 = [];
  for (let i = 0; i < data.length; i++) {
    if (i < 6) {
      rows6.push(data[i]);
    } else {
      rows12.push(data[i]);
    }
  }
  return (
    <div className="bidinglist">
      <div className="grid">
        {rows6.map((row, index) => (
          <div key={row.gameid} className="col-12 md:col-12 lg:col-12 game">
            <div className="title">{row.display}</div>
            <img
              src={`${baseurl}/images/${row.gamename}.png`}
              alt={`${baseurl}/images/${row.gamename}.png`}
            />
            <div className="rate">
              <div>Bid Value</div>
              {row.bid_rate}%
            </div>
            {logedin == 1 ? (
              <Button
                href={`/user/makebid/${row.gameid}`}
                variant="contained"
                color="info"
              >
                Make Bid
              </Button>
            ) : (
              <Button variant="contained" disabled color="info">
                Make Bid
              </Button>
            )}
          </div>
        ))}
      </div>
      <Divider />
      <div className="grid">
        {rows12.map((row, index) => (
          <div key={row.gameid} className="col-12 md:col-12 lg:col-12 game">
            <div className="title">{row.display}</div>
            <img
              src={`${baseurl}/images/${row.gamename}.png`}
              alt={`${baseurl}/images/${row.gamename}.png`}
            />
            <div className="rate">
              <div>Bid Value</div>
              {row.bid_rate}%
            </div>
            {logedin == 1 ? (
              <Button
                href={`/user/makebid/${row.gameid}`}
                variant="contained"
                color="info"
              >
                Make Bid
              </Button>
            ) : (
              <Button variant="contained" disabled color="info">
                Make Bid
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
