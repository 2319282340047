import React from 'react'
import { WelCome } from '../../../components/user/WelCome'
import { BidList } from '../../../components/user/BidList'
export const DashBoard = () => {
  return (
    <div>
        <WelCome />
        <BidList logedin="1" />
    </div>
  )
}
