import React, { useState, useRef, useEffect } from "react";
import "./incomedetails.scss";
import axios from "axios";
import { Card } from "primereact/card";
import { Button } from "@mui/material";
export const IncomeDetails = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actmemid = localStorage.getItem("damanmemid");
  const [refIncome, setRefIncome] = useState(0);
  const [regIncome, setRegIncome] = useState(0);
  const [autoIncome, setAutoIncome] = useState(0);
  const [levelIncome, setLevelIncome] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getIncomeDetails",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setRefIncome(resp.data.refIncome);
        setRegIncome(resp.data.totRegular)
        setAutoIncome(resp.data.totAuto)
        setLevelIncome(resp.data.totLevel)
      }
    };
    fetchData();
  }, []);
  return (
    <div className="incomedetails">
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-3">
            <Card>
              <div className="cont">
                <i className="pi pi-sync"></i>
                <div>REFERRAL INCOME</div>
                <div>{refIncome}</div>
                <Button variant="outlined" size="small" color="secondary" href="/user/displaydetails/referral" >Details</Button>
              </div>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-3">
            <Card>
              <div className="cont">
                <i className="pi pi-gift"></i>
                <div>Auto Bet Income</div>
                <div>{autoIncome}</div>
                <Button variant="outlined" size="small" color="secondary" href="/user/displaydetails/referral" >Details</Button>
              </div>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-3">
            <Card>
              <div className="cont">
                <i className="pi pi-gift"></i>
                <div>Regular Bet</div>
                <div>{regIncome}</div>
                <Button variant="outlined" size="small" color="secondary" href="/user/displaydetails/referral" >Details</Button>
              </div>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-3">
            <Card>
              <div className="cont">
                <i className="pi pi-gift"></i>
                <div>Level Income</div>
                <div>{levelIncome}</div>
                <Button variant="outlined" size="small" color="secondary" href="/user/displaydetails/referral" >Details</Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
