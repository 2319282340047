import React, { useState, useRef, useEffect } from "react";
import "./withdraw.scss";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from "primereact/toast";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";
export const Withdraw = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actmemid = localStorage.getItem("damanmemid");
  const [btnstatus, setBtnStatus] = useState(0);
  const [btntrans, setBtnTrans] = useState(0);
  const [isProfile, setisProfile] = useState(0);
  const [balance, setBalance] = useState(0);
  const [unBetAmt, setUnBetAmt] = useState(0);
  const [data, setData] = useState({});
  const [rejectdata, setRejectData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getwithdrawbalance",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      const respProfile = await axios.post(
        url + "/user/getUserProfile",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setBalance(resp.data.balance);
        formik.setFieldValue("avlbalance", resp.data.balance);
        setUnBetAmt(resp.data.unbetAmt);
        setisProfile(respProfile.data.found);
        setData(resp.data.withdata);
        setRejectData(resp.data.rejectdata)
        if (resp.data.balance * 1 >= 0 && parseInt(resp.data.unbetAmt) * 1 === 0) {
          setBtnStatus(1);
        }
        // setData(resp.data.userunclaimedbid);
        // setData1(resp.data.userclaimedbid);
      }
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    avlbalance: Yup.mixed("Invalid, Please Check!!!").required(
      "Field can not be empty."
    ),
    withamount: Yup.number("Balance Issue")
      .min(200)
      .max(Yup.ref("avlbalance"), "Cannot Exceed Max Balance")
      .required("Field can not be empty"),
  });
  const formik = useFormik({
    initialValues: {
      avlbalance: 0,
      withamount: 0,
    },
    validationSchema,
    onSubmit: async (values) => {
      setBtnStatus(2);
      const resp = await axios.post(
        url + "/user/confirmwithdraw",
        {
          values,
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Bid Submitted Successfully",
        });
        setBtnStatus(3);
        window.location.reload(true)
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const transferToBetWallet = async () => {
    setBtnTrans(1);
    const resp = await axios.post(
      url + "/user/transtobetwallet",
      {
        balance,
        actmemid,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Transfer Successfully Done",
      });
    }
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="withdraw">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <Card className="cust-bg">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/favicon.png" width="100" alt="" />
                    <div className="bidfor">
                      <div>UNBET AMT</div>
                      <div>{unBetAmt.toFixed(2)}</div>
                    </div>
                    <div className="bidfor">
                      <div>WITHDRAW BALANCE</div>
                      <div>{balance.toFixed(2)}</div>
                    </div>
                    <div className="component">
                      <InputText
                        type="hidden"
                        name="avlbalance"
                        placeholder="Email Address"
                        onChange={formik.handleChange}
                        value={formik.values.avlbalance}
                      />
                      {getFormErrorMessage("avlbalance")}
                    </div>
                    <div className="component">
                      {balance * 1 > 0 ? (
                        <>
                          {btntrans === 0 ? (
                            <Button
                              type="button"
                              label="Trans To Wallet "
                              onClick={() => transferToBetWallet()}
                            />
                          ) : (
                            <Button type="button" label="Transferred" />
                          )}
                        </>
                      ) : (
                        <Button type="button" label="Transfer Not Possible" />
                      )}
                    </div>
                    <div className="component">
                      <div>Withdraw Amount</div>
                      <InputText
                        type="text"
                        name="withamount"
                        placeholder="Withdraw Amount"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.withamount}
                      />
                      {getFormErrorMessage("withamount")}
                    </div>
                    {isProfile === 0 ? (
                      <Link to={"/user/profile"}>
                        <Button
                          url="/user/profile"
                          label="Please Update Banking"
                          severity="warning"
                        />
                      </Link>
                    ) : (
                      <div className="component">
                        {btnstatus === 0 ? (
                          <div>
                            <Button
                              type="button"
                              label="Withdraw Not Possible"
                              size="large"
                            />
                          </div>
                        ) : (
                          <>
                            {btnstatus === 1 ? (
                              <div className="flex flex-column gap-3">
                                {/* <div className="flex">
                                  <Checkbox
                                    onChange={(e) => setChecked(e.checked)}
                                    checked={checked}
                                  ></Checkbox>
                                  <label htmlFor="ingredient1" className="ml-2">
                                    In Wallet [Default Bank Account]
                                  </label>
                                </div> */}
                                <Button
                                  type="submit"
                                  label="Withdraw"
                                  size="large"
                                />
                              </div>
                            ) : (
                              <>
                                {btnstatus === 2 ? (
                                  <Button
                                    type="button"
                                    label="Withdraw Submitting"
                                    size="large"
                                  />
                                ) : (
                                  <Button
                                    type="button"
                                    label="Withdraw Submited"
                                    size="large"
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
            <Card>
              <div className="actbet">Withdraw History</div>
              <Divider />
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">SL. No.</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        key={row.uuid}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {moment(row.tran_date).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell align="center">
                          {row.commission.toFixed(2)}
                        </TableCell>
                        <TableCell align="center">
                          {row.withdraw === 0 ? "PENDING" : "SUCCESS"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
            <Card>
              <div className="actbet">Reject</div>
              <Divider />
              <DataTable value={rejectdata} tableStyle={{ minWidth: '50rem' }}>
                <Column field="tran_date" header="Date"></Column>
                <Column field="naration" header="Reason"></Column>
                <Column field="commission" header="Amount"></Column>
            </DataTable>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
