import React, { useState, useRef, useEffect } from "react";
import "./biddinglist.scss";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Form, FormikProvider, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
export const BiddingList = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getgamelist", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.gamelist);
      }
    };
    fetchData();
  }, []);
  const updateHandle = async (gameid) => {
    var amt = document.getElementById("_y" + gameid).value;
    document.getElementById("_x" + gameid).innerHTML = amt;
    const resp = await axios.post(url + "/admin/updategamebidrate", {
      gameid,
      amt,
    },{
        headers: {
            "x-api-key": apikey,
          },
    });
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Value Updated Successfully.",
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong.",
      });
    }
    //var i = document.getElementsByClassName("_x" + memid).innerHTML
    //alert(i)
    //  console.log(resp.data)
  };

  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="biddinglist">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12 right">
          <Card title="GAME LIST">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">GAME ID</TableCell>
                    <TableCell align="center">GAME NAME</TableCell>
                    <TableCell align="center">BID RATE</TableCell>
                    <TableCell align="center">NEW RATE</TableCell>
                    <TableCell align="center">UPDATE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{row.gameid}</TableCell>
                      <TableCell align="center">{row.display}</TableCell>
                      <TableCell align="center">
                        <span id={"_x" + row.gameid}>
                          {row.bid_rate.toFixed(2)}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <InputText size="small" id={"_y" + row.gameid} />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          size="small"
                          label="Confirm"
                          onClick={() => updateHandle(row.gameid)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
