import React, { useState, useRef, useEffect } from "react";
import { Card } from "primereact/card";
import axios from "axios";
import "./mydown.scss"
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
export const MyDown = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actmemid = localStorage.getItem("damanmemid");
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getmydown",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        // setBalance(resp.data.balance)
        // setGameList(resp.data.gamelist)
        // setActGameId(resp.data.actbet.unique_id)
        // setRate(resp.data.gamelist.bid_rate)
        setData(resp.data.resp);
        // setStartTime(moment(resp.data.actbet.bet_start_at).utcOffset(330).format("YYYY-MM-DD HH:mm:ss"))
        // setEndTime(moment(resp.data.actbet.bet_end_at).utcOffset(330).format("YYYY-MM-DD HH:mm:ss"))
        // formik.setFieldValue("balance",resp.data.balance)
      }
    };
    fetchData();
  }, []);
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="mydown">
      <Card>
        <div className="actbet">My Downline</div>
        <Divider />
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">SL. No.</TableCell>
                <TableCell align="center">User Id</TableCell>
                <TableCell align="center">Login Id</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.uuid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{row.memberid}</TableCell>
                  <TableCell align="center">{row.mobile}</TableCell>
                  <TableCell align="center">{row.is_active===0?"NOt Active":"Active"}</TableCell>
                  <TableCell align="center">Details</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
};
