import React, { useState, useRef, useEffect } from "react";
import "./cryptodeposit.scss";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Form, FormikProvider, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
export const CryptoDeposit = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({});
  const [myadd, setMyAdd] = useState("")
  const [bep20, setBep20] = useState("")
  const [password, setPassword] = useState("")
  const actmemid = localStorage.getItem("damanmemid");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getcryptodeposits",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setMyAdd(resp.data.myadd);
        setData(resp.data.cryptodepositlist);
        formik.setFieldValue("myadd",resp.data.myadd)
      }
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    memberid: Yup.string().required(),
    myadd: Yup.string().required(),
    tranid: Yup.mixed("Invalid Transaction Id, Please Check!!!").required(
      "Field can not be empty."
    ),
    amount: Yup.number("Invalid Transaction Id, Please Check!!!").required(
      "Field can not be empty."
    ),
  });
  const formik = useFormik({
    initialValues: {
      memberid: actmemid,
      myadd: "",
      tranid: "",
      amount: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/user/cryptodepostreq",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
      } else {
        setButtonDisabled(0);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const copyRef = (x) => {
    navigator.clipboard.writeText(x);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Received Link Copied.",
    });
  };
  const setBep = () =>{
    setVisible(true)
  }
  const setAddress = async() =>{
    const resp = await axios.post(
      url + "/user/setbep20address",
      {
        actmemid,
        bep20,
        password
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if(resp.status===200){
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
      setVisible(false)
      setMyAdd(bep20);
      formik.setFieldValue("myadd",bep20)
    }else{
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
    }
  }
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="cryptodeposit">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-5 left">
          <div className="content">
            <Card className="semiTrans">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/usdtadd.webp" width="150" alt="" />
                    <h3>CRYPTO DEPOSIT</h3>
                    <div className="address">
                      <div className="cont">
                        <div>MY ADD:</div>
                        <div>{myadd===""?
                        <Button type="button" label="Please Set Bep20 Address" severity="danger" outlined onClick={()=>setBep()} />
                        :
                        myadd
                        }</div>
                      </div>
                      <div className="cont">
                        <div>DEPOSIT ADD:</div>
                        <div className="dipadd">
                          <div>0x7944aF14fB9715feF3699d663ff30094780cE0a5</div>
                          <i className="pi pi-copy" onClick={()=> copyRef('0x7944aF14fB9715feF3699d663ff30094780cE0a5')}></i>
                        </div>
                      </div>
                    </div>
                    <div className="component">
                      <InputText
                        type="hidden"
                        name="myadd"
                        placeholder="My Add"
                        onChange={formik.handleChange}
                        value={formik.values.myadd}
                      />
                    </div>
                    <div className="component">
                      <InputText
                        type="text"
                        name="tranid"
                        placeholder="TranHash"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.tranid}
                      />
                      {getFormErrorMessage("tranid")}
                    </div>
                    <div className="component">
                      <InputText
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        onChange={formik.handleChange}
                        value={formik.values.amount}
                      />
                      {getFormErrorMessage("amount")}
                    </div>

                    <div className="component">
                      {isButtonDisabled === 0 ? (
                        <Button type="submit" label="Submit" size="large" />
                      ) : (
                        <>
                          {isButtonDisabled === 1 ? (
                            <Button
                              type="button"
                              disabled
                              label="Submitting"
                              size="large"
                            />
                          ) : (
                            <Button
                              type="button"
                              label="Submitted"
                              size="large"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-7 right">
          <Card title="CRYPTO DEPOSITS">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">USDT</TableCell>
                    <TableCell align="center">RATE</TableCell>
                    <TableCell align="center">AMOUNT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.usdt}</TableCell>
                      <TableCell align="center">{row.usdt_rate}</TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
      <Dialog
        header="SET BEP20 ADDRESS"
        visible={visible}
        style={{ width: "350px" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="dialog flex flex-column gap-2">
          <div>Received Link [BEP20 USDT]</div>
          <InputText placeholder="BEP20 Address" value={bep20} onChange={e => setBep20(e.target.value)} />
          <Password placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
          <Button label="Confirm" outlined onClick={()=>setAddress()}  />
        </div>
      </Dialog>
    </div>
  )
}
