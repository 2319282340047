import React, { useState, useRef, useEffect } from "react";
import "./inrdeposit.scss";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Form, FormikProvider, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import qrcode  from "../../../images/qrcode.webp" 
import qrcode1  from "../../../images/qrcode1.webp" 
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
export const InrDeposit = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [actQrCode, setActQrCode] = useState(0);
  const [data, setData] = useState({});
  const actmemid = localStorage.getItem("damanmemid");
  const [selectedPayId, setSelectedPayId] = useState(null);
  const [visible, setVisible] = useState(false);
  const payids = [
    { name: "PAYID32X", days: "PAYID32X" },
  ];
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getinrdeposits",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      
      if (resp.status === 200) {
        setData(resp.data.inrdepositlist);
      }
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    memberid: Yup.string().required(),
    tranid: Yup.mixed("Invalid Transaction Id, Please Check!!!").required(
      "Field can not be empty."
    ),
    amount: Yup.number("Invalid Transaction Id, Please Check!!!").required(
      "Field can not be empty."
    ).min(200),
  });
  const formik = useFormik({
    initialValues: {
      memberid: actmemid,
      tranid: "",
      amount: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (selectedPayId === null) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please Select PAy Id",
        });
        return false;
      }
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/user/inrdepostreq",
        {
          values,
          selectedPayId
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
      } else {
        setButtonDisabled(0);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const copyRef = (x) => {
    navigator.clipboard.writeText(x);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "UPI ID COPIED SUCCESSFULLY",
    });
  };
  const displayDialog = (x) =>{
    setActQrCode(x)
    setVisible(true)
  }
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="inrdeposit">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-5 left">
          <div className="content">
            <Card className="semiTrans">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    {/* <img src="/images/qrcode.webp" width="150" alt="" /> */}
                    <h3>INR DEPOSIT</h3>
                    <div className="qrlist">
                      <div className="qrcont">
                        <div>PAYID32X</div>
                        <div
                          className="upiid"
                          onClick={() => copyRef("laxmienterprisesdamanautobot@axl")}
                        >
                          laxmienterprisesdamanautobot@axl
                        </div>
                        <i className="pi pi-qrcode" onClick={()=>displayDialog(1)} />
                      </div>
                    </div>
                    <div className="component">
                      <Dropdown
                        value={selectedPayId}
                        onChange={(e) => setSelectedPayId(e.value)}
                        options={payids}
                        optionLabel="name"
                        placeholder="Select Pay Id"
                      />
                    </div>
                    <div className="component">
                      <InputText
                        type="text"
                        name="tranid"
                        placeholder="Transaction Id (UTR)"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.tranid}
                      />
                      {getFormErrorMessage("tranid")}
                    </div>
                    <div className="component">
                      <InputText
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        onChange={formik.handleChange}
                        value={formik.values.amount}
                      />
                      {getFormErrorMessage("amount")}
                    </div>
                    <div className="component">
                    {isButtonDisabled === 0 ? (
                      <Button type="submit" label="Submit" size="large" />
                    ) : (
                      <>
                        {isButtonDisabled === 1 ? (
                          <Button
                            type="button"
                            disabled
                            label="Submitting"
                            size="large"
                          />
                        ) : (
                          <Button
                            type="button"
                            label="Submitted"
                            size="large"
                          />
                        )}
                      </>
                    )}
                  </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-7 right">
          <Card title="INR DEPOSITS">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Reason</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">{row.status==='Success'?"NA":row.reject_reason}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
      <Dialog
        header="QRCODE"
        visible={visible}
        style={{ width: "350px" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p className="m-0">
          {actQrCode===1?
          <img src={qrcode} alt="" width="100%" />
          :
          <img src={qrcode1} alt="" width="100%" />
          }
        </p>
      </Dialog>
    </div>
  );
};
