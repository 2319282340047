import React, { useState, useRef, useEffect } from "react";
import "./userslist.scss";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import axios from "axios";
import { Toast } from "primereact/toast";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
export const UserList = () => {
    const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getuserslist", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.inrdepositlist);
      }
    };
    fetchData();
  }, []);
  const approve = async (tranid) => {
    const resp = await axios.post(
      url + "/admin/get",
      { tranid },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      window.location.reload(true);
    }
  };
  const reject = () => {};
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="userslist">
    <div className="grid">
      <div className="col-12 md:col-12 lg:col-12 right">
        <Card title="USERS LIST">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Member Id</TableCell>
                  <TableCell align="center">Mobile</TableCell>
                  <TableCell align="center">Password</TableCell>
                  <TableCell align="center">Is Active</TableCell>
                  <TableCell align="center">Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={row.uuid}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">
                      {moment(row.tran_date)
                        .utcOffset(330)
                        .format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell align="center">{row.memberid}</TableCell>
                    <TableCell align="center">{row.mobile}</TableCell>
                    <TableCell align="center">{row.password}</TableCell>
                    <TableCell align="center">{row.is_active}</TableCell>
                    <TableCell align="center"><Link to={`/admin/edituser/${row.memberid}`}>Edit</Link></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </div>
  </div>
);
}
