// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-light .dashboardbid .p-card {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(238, 244, 247);
  color: #000;
}
.theme-light .dashboardbid .p-card .p-card-body .p-card-content .cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.theme-light .dashboardbid .p-card .p-card-body .p-card-content .cont .head {
  background-color: rgb(255, 146, 4);
  color: #FFF;
  padding: 10px;
  border-radius: 8px;
}
.theme-light .dashboardbid .p-card .p-card-body .p-card-content .cont .data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  border-radius: 8px;
  box-shadow: 1px 1px 1px #990000;
}
.theme-dark .dashboardbid .p-card {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #1c3645;
  color: whitesmoke;
}
.theme-dark .dashboardbid .p-card .p-card-body .p-card-content .cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.theme-dark .dashboardbid .p-card .p-card-body .p-card-content .cont .head {
  background-color: rgb(255, 146, 4);
  color: #FFF;
  padding: 10px;
  border-radius: 8px;
}
.theme-dark .dashboardbid .p-card .p-card-body .p-card-content .cont .data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  border-radius: 8px;
  box-shadow: 1px 1px 1px #990000;
}`, "",{"version":3,"sources":["webpack://./src/components/user/dashboard/dashboardbid.scss"],"names":[],"mappings":"AAII;EACI,yDAAA;EACF,4BAAA;EACA,sBAAA;EACA,oCAAA;EACA,WAAA;AAHN;AAMU;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAJZ;AAKY;EACI,kCAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;AAHhB;AAKY;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,kBAAA;EACA,+BAAA;AAHhB;AArBI;EACI,yDAAA;EACF,4BAAA;EACA,sBAAA;EACA,yBAAA;EACA,iBAAA;AAuBN;AApBU;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAsBZ;AArBY;EACI,kCAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;AAuBhB;AArBY;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,kBAAA;EACA,+BAAA;AAuBhB","sourcesContent":["@import \"../../../style.scss\";\n.dashboardbid {\n  @include themify($themes) {\n    \n    .p-card {\n        background-image: url(\"../../../images/background.png\");\n      background-repeat: no-repeat;\n      background-size: cover;\n      background-color: themed(\"cardLight\");\n      color: themed(\"textColor\");\n      .p-card-body {\n        .p-card-content {\n          .cont {\n            display: flex;\n            flex-direction: column;\n            gap: 8px;\n            .head{\n                background-color: rgb(255, 146, 4);\n                color: #FFF;\n                padding: 10px;\n                border-radius: 8px;\n            }\n            .data{\n                display: flex;\n                align-items: center;\n                justify-content: space-between;\n                padding: 3px;\n                border-radius: 8px;\n                box-shadow: 1px 1px 1px #990000;\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
