import React from "react";
import "./home.scss";
import { Divider } from "primereact/divider";
import { BidList } from "../../components/user/BidList";
import { Button } from "@mui/material";
import { DashBoardMorning } from "../../components/user/dashboard/DashBoardMorning";
import { DashBoardEvening } from "../../components/user/dashboard/DashBoardEvening";
export const Home = () => {
  return (
    <div className="home">
      <div className="homecont">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <div>WELCOME TO</div>
            <div className="homehead">DAMAN BOT</div>
            <div className="homemessage">TRADING</div>
            <Divider />
            <p>Even when he falls on straw, Schlemiel stumbles on a stone.</p>
            <Divider />
            <div className="buttons">
              <Button href="/login" variant="outlined" color="warning">
                Log In
              </Button>
              <Button href="/signup/191201" variant="contained" color="success">
                Sign Up
              </Button>
            </div>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
            <div className="grid">
              <div className="col-12 md:col-12 lg:col-6">
                <DashBoardMorning />
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <DashBoardEvening />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="news">Please See News for Latest Update.</div>
      <BidList logedin="0" />
      {/* <div className="allgames">
        <div className="grid">
          <div className="col-6 md:col-12 lg:col-3">
            <img src={casino} alt="" />
          </div>
          <div className="col-6 md:col-12 lg:col-3">
            <img src={boxing} alt="" />
          </div>
          <div className="col-6 md:col-12 lg:col-3">
            <img src={cricket} alt="" />
          </div>
          <div className="col-6 md:col-12 lg:col-3">
            <img src={damangame} alt="" />
          </div>
        </div>
        <div className="grid">
          <div className="col-6 md:col-12 lg:col-3">
            <img src={football} alt="" />
          </div>
          <div className="col-6 md:col-12 lg:col-3">
            <img src={ludo} alt="" />
          </div>
          <div className="col-6 md:col-12 lg:col-3">
            <img src={poker} alt="" />
          </div>
          <div className="col-6 md:col-12 lg:col-3">
            <img src={rummy} alt="" />
          </div>
        </div>
        <div className="grid">
          <div className="col-6 md:col-12 lg:col-3">
            <img src={patti} alt="" />
          </div>
          <div className="col-6 md:col-12 lg:col-3">
            <img src={colourprediction} alt="" />
          </div>
          <div className="col-6 md:col-12 lg:col-3">
            <img src={subwaysuffer} alt="" />
          </div>
          <div className="col-6 md:col-12 lg:col-3">
            <img src={spinhorse} alt="" />
          </div>
        </div>
      </div> */}
    </div>
  );
};
