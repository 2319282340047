import React, { useState, useRef, useEffect } from "react";
// import "./admininrwithdraw.scss";
import { Card } from "primereact/card";
import axios from "axios";
import { Button } from "primereact/button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Dialog } from "primereact/dialog";
import moment from "moment";
export const AdminCryptoWithdraw = () => {
    const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [dataB, setDataB] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(url + "/admin/getinrwithdrawlist",{tp:"WALLET"}, {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.respwithlist);
      }
    };
    fetchData();
  }, []);
  const getBankDetails = async (memId) => {
    const resp = await axios.post(
      url + "/admin/getwalletadd",
      { memId },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setVisible(true);
      setDataB(resp.data.add);
    }
  };
  const approve = async (tranid) => {
    const resp = await axios.post(
      url + "/admin/approveinrwithdraw",
      { tranid, trtype:"USDT" },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      window.location.reload(true);
    }
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="admininrwithdraw">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12 right">
          <Card title="Crypto Withdraw">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Member Id</TableCell>
                    <TableCell align="center">Address</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Approv</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.memberid}</TableCell>
                      <TableCell align="center">
                        <Button onClick={() => getBankDetails(row.memberid)}>
                          Wallet
                        </Button>
                      </TableCell>
                      <TableCell align="center">{row.commission}</TableCell>
                      <TableCell align="center">
                      <Button
                          label="Approve"
                          onClick={() => approve(row._id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
      <Dialog
        visible={visible}
        modal
        header="Address"
        style={{ width: "50rem" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="data">
          <p className="m-0">Address</p>
          <p className="m-0">{dataB===""?"Not Set":dataB}</p>
        </div>
        
      </Dialog>
    </div>
  )
}
