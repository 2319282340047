import React, { useState, useRef, useEffect } from "react";
import "./admindashboard.scss"
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import axios from "axios";
export const AdminDashBoard = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [totMember, setTotMember] = useState(0)
  const [totActive, setTotActive] = useState(0)
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(
        url + "/admin/getadmindashboard",
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      
      if (resp.status === 200) {
       setTotActive(resp.data.totActive);
       setTotMember(resp.data.totCount)
      }
    };
    fetchData();
  }, []);
  console.log(totActive)
  return (
    <div className='admindashboard'>
      <div className="datas">
      <div className="cont">
        <h3>Total Member</h3>
        <p>{totMember}</p>
      </div>
      <div className="cont">
        <h3>Total Active</h3>
        <p>{totActive}</p>
      </div>
      </div>
      
    </div>
  )
}
