import React from 'react'
import "./walletmanage.scss"
import { Card } from 'primereact/card'
export const WalletMange = () => {
  return (
    <div className='walletmanage'>
        <div className="content">
            <div className="grid">
            <div className="col-12 md:col-12 lg:col-4 left">
                <Card title="Regular Bid">
                    
                </Card>
            </div>
            </div>
        </div>
    </div>
  )
}
