import React, { useContext } from "react";
import "./adminappbar.scss";
import { Menubar } from "primereact/menubar";
import { DarkModeContext } from "../../context/darkModeContext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
export const AdminAppBar = () => {
  const Navigate = useNavigate();
  const { toggle, darkMode } = useContext(DarkModeContext);
  const items = [
    {
      label: "Home",
      icon: "pi pi-home",
      url: "/admin/",
    },
    {
      label: "Deposits",
      icon: "pi pi-star",
      items: [
        {
          label: "INR Deposit",
          icon: "pi pi-bolt",
          url: "/admin/inrdeposit",
        },
        {
          label: "INR Deposit List",
          icon: "pi pi-bolt",
          url: "/admin/inrdepositlist",
        },
        {
          label: "Crypto Deposit",
          icon: "pi pi-server",
          url:"/admin/cryptodeposit"
        },
      ],
    },
    {
      label: "Withdraw",
      icon: "pi pi-star",
      items: [
        {
          label: "INR Withdraw",
          icon: "pi pi-bolt",
          url: "/admin/inrwithdraw",
        },
        {
          label: "Crypto Withdraw",
          icon: "pi pi-bolt",
          url: "/admin/cryptowithdraw",
        },
        // {
        //   label: "INR Deposit List",
        //   icon: "pi pi-bolt",
        //   url: "/admin/inrdepositlist",
        // },
        // {
        //   label: "Crypto Deposit",
        //   icon: "pi pi-server",
        // },
      ],
    },
    {
      label: "Daily Betting",
      icon: "pi pi-indian-rupee",
      url: "/admin/dailybetting",
    },
    {
      label: "Users",
      icon: "pi pi-bitcoin",
      url: "/admin/userslist",
    },
    {
      label: "Bidding",
      icon: "pi pi-star",
      items: [
        {
          label: "Biding List",
          icon: "pi pi-bolt",
          url: "/admin/biddinglist/",
        },
        {
          label: "Diposit",
          icon: "pi pi-server",
        },
      ],
    },
    {
      label: "Transaction Details",
      icon: "pi pi-star",
      items: [
        {
          label: "Bidding",
          icon: "pi pi-bolt",
        },
        {
          label: "Diposit",
          icon: "pi pi-server",
        },
      ],
    },
    {
      label: "Win Go",
      icon: "pi pi-star",
      items: [
        {
          label: "Set Up",
          icon: "pi pi-bolt",
          url: "/admin/wingosetup",
        },
        {
          label: "Diposit",
          icon: "pi pi-server",
        },
      ],
    },
  ];
  const start = (
    <div className="appstart">
      <img alt="logo" src="/images/favicon.png" height="40" className="mr-2" />
      <span className="headlogo">DAMAN BOT</span>
    </div>
  );
  const end = (
    <div className="flex align-items-center gap-1">
      {darkMode ? (
        <span
          className="pi pi-moon"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <span
          className="pi pi-sun"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        />
      )}
      <Button
        label="Log Out"
        icon="pi pi-power-off"
        size="small"
        severity="danger"
        className="ml-3"
        onClick={() => logout()}
      />
    </div>
  );
  const logout = () => {
    localStorage.removeItem("damanmenadmin");
    Navigate("/");
  };
  return (
    <div>
      <div className="card adminappbar">
        <Menubar model={items} start={start} end={end} />
      </div>
    </div>
  );
};
