// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-light .home .homecont {
  background-color: rgb(238, 244, 247);
  color: #000;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 60px;
}
.theme-light .home .homecont .left .buttons {
  display: flex;
  gap: 10px;
}
.theme-light .home .homecont .homehead {
  font-size: 56px;
  font-family: Poppins;
}
.theme-light .home .homecont .homemessage {
  font-weight: 700;
  letter-spacing: 10px;
}
@media (max-width: 900.1px) {
  .theme-light .home .homecont {
    padding: 100px 10px;
  }
}
.theme-light .home .news {
  padding: 5px;
  background-color: darkslategrey;
  color: aliceblue;
}
.theme-dark .home .homecont {
  background-color: #1e3948;
  color: whitesmoke;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 60px;
}
.theme-dark .home .homecont .left .buttons {
  display: flex;
  gap: 10px;
}
.theme-dark .home .homecont .homehead {
  font-size: 56px;
  font-family: Poppins;
}
.theme-dark .home .homecont .homemessage {
  font-weight: 700;
  letter-spacing: 10px;
}
@media (max-width: 900.1px) {
  .theme-dark .home .homecont {
    padding: 100px 10px;
  }
}
.theme-dark .home .news {
  padding: 5px;
  background-color: darkslategrey;
  color: aliceblue;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.scss"],"names":[],"mappings":"AAGI;EACE,oCAAA;EACA,WAAA;EACA,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,mBAAA;AAFN;AAIQ;EACE,aAAA;EACA,SAAA;AAFV;AAKM;EACE,eAAA;EACA,oBAAA;AAHR;AAKM;EACE,gBAAA;EACA,oBAAA;AAHR;AAMM;EAtBF;IAuBI,mBAAA;EAHN;AACF;AAKI;EACE,YAAA;EACA,+BAAA;EACA,gBAAA;AAHN;AA1BI;EACE,yBAAA;EACA,iBAAA;EACA,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,mBAAA;AA4BN;AA1BQ;EACE,aAAA;EACA,SAAA;AA4BV;AAzBM;EACE,eAAA;EACA,oBAAA;AA2BR;AAzBM;EACE,gBAAA;EACA,oBAAA;AA2BR;AAxBM;EAtBF;IAuBI,mBAAA;EA2BN;AACF;AAzBI;EACE,YAAA;EACA,+BAAA;EACA,gBAAA;AA2BN","sourcesContent":["@import \"../../style.scss\";\n.home {\n  @include themify($themes) {\n    .homecont {\n      background-color: themed(\"bg\");\n      color: themed(\"textColor\");\n      background-image: url(\"../../images/background.png\");\n      background-repeat: no-repeat;\n      background-size: cover;\n      padding: 200px 60px;\n      .left {\n        .buttons {\n          display: flex;\n          gap: 10px;\n        }\n      }\n      .homehead {\n        font-size: 56px;\n        font-family: Poppins;\n      }\n      .homemessage {\n        font-weight: 700;\n        letter-spacing: 10px;\n      }\n      $breakpoint-tablet: 900.1px;\n      @media (max-width: $breakpoint-tablet) {\n        padding: 100px 10px;\n      }\n    }\n    .news {\n      padding: 5px;\n      background-color: darkslategrey;\n      color: aliceblue;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
