import React, { useState, useRef, useEffect } from "react";
import "./admindashboard.scss"
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
export const AdminDashBoard = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [totMember, setTotMember] = useState(0)
  const [totActive, setTotActive] = useState(0)
  const [isActive, setIsActive] = useState("")
  const [per, setPer] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(
        url + "/admin/getadmindashboard",
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      
      if (resp.status === 200) {
       setTotActive(resp.data.totActive);
       setTotMember(resp.data.totCount)
      }
    };
    fetchData();
  }, []);
  const confirmSubmit = async () =>{
    const resp = await axios.post(
      url + "/admin/confirmSubmit",{
        isActive,
        per
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if(resp.status===200){
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Transaction Successfully Done",
      });
    }
  }
  return (
    <div className='admindashboard'>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-3">
            <div className="topupbonus border-2 border-dashed surface-border border-round surface-ground p-2">
              <div className="isa flex flex-column gap-3">
                <InputText placeholder="Active:1 / Inactive: 0" value={isActive} onChange={e => setIsActive(e.target.value)} />
                <InputText placeholder="Percentage" value={per} onChange={e => setPer(e.target.value)} />
                <Button label="Confirm" onClick={()=> confirmSubmit()} />
              </div>
            </div>
        </div>
      </div>
      <div className="datas">
      <div className="cont">
        <h3>Total Member</h3>
        <p>{totMember}</p>
      </div>
      <div className="cont">
        <h3>Total Active</h3>
        <p>{totActive}</p>
      </div>
      </div>
      
    </div>
  )
}
