import React, { useState, useRef, useEffect } from "react";
import "./makebid.scss";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
import { BidList } from "../../../components/user/BidList";
import Countdown from 'react-countdown';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { Divider } from "primereact/divider";
export const MakeBid = () => {
  let { gameid } = useParams("gameid");
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actmemid = localStorage.getItem("damanmemid");
  const [btnstatus, setBtnStatus] = useState(0);
  const [balance, setBalance] = useState(0)
  const [actGameId, setActGameId] = useState(null)
  const [rate, setRate] = useState(0)
  const [gamelist, setGameList] = useState({})
  const [data, setData] = useState({})
  const [startTime, setStartTime] = useState("2025-10-14 00:00:00")
  const [endTime, setEndTime] = useState("2025-10-14 00:00:00")
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getmakebiddata",
        {
            actmemid,
            gameid
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if(resp.status===200){
        setBalance(resp.data.balance)
        setGameList(resp.data.gamelist)
        setActGameId(resp.data.actbet.unique_id)
        setRate(resp.data.gamelist.bid_rate)
        setData(resp.data.useractbid)
        setStartTime(moment(resp.data.actbet.bet_start_at).utcOffset(330).format("YYYY-MM-DD HH:mm:ss"))
        setEndTime(moment(resp.data.actbet.bet_end_at).utcOffset(330).format("YYYY-MM-DD HH:mm:ss"))
        formik.setFieldValue("balance",resp.data.balance)
      }
    }
    fetchData()
  },[])
  const validationSchema = Yup.object({
    gameid: Yup.mixed("Invalid Email, Please Check!!!").required(
      "Field can not be empty."
    ),
    balance: Yup.number("Balance Issue").required(""),
    bidamount: Yup.number("Invalid Bid, Please Check!!!").required(
      "Field can not be empty."
    ).min(200,"Minimum Bid is 200").max(Yup.ref('balance'), 'Cannot Exceed Max Balance'),
  });
  const formik = useFormik({
    initialValues: {
      gameid: gameid,
      balance:0,
      bidamount: 0
    },
    validationSchema,
    onSubmit: async (values) => {
      setBtnStatus(2)
      const resp = await axios.post(
        url + "/user/consirmbid",
        {
          values,
          actmemid,
          userbal:balance,
          actGameId,
          rate
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Bid Submitted Successfully",
        });
        setBtnStatus(3)
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="makebid">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <Card className="cust-bg">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/favicon.png" width="100" alt="" />
                    <div className="bidfor">
                      <div>BID FOR</div>
                      <div>{gamelist.display}@{gamelist.bid_rate}</div>
                    </div>
                    <div className="bidfor">
                      <div>WALLET BALANCE</div>
                      <div>{balance.toFixed(2)}</div>
                    </div>
                    <div className="component">
                      <InputText
                        type="hidden"
                        name="gameid"
                        placeholder="Email Address"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.gameid}
                      />
                      {getFormErrorMessage("gameid")}
                    </div>
                    <div className="component">
                        <div>Bidding Amount</div>
                      <InputText
                        type="text"
                        name="bidamount"
                        placeholder="Bidding Amount"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.bidamount}
                      />
                      {getFormErrorMessage("bidamount")}
                    </div>
                    <div className="bidfor">
                      <div><Countdown date={startTime}  onComplete={()=>setBtnStatus(1)} /></div>
                      <div><Countdown date={endTime} onComplete={()=>setBtnStatus(0)} /></div>
                    </div>
                    
                    <div className="component">
                      {btnstatus===0?
                      <Button type="button" label="Get Ready" size="large" />
                      :
                      <>
                      {btnstatus===1?
                      <Button type="submit" label="Bid Now" size="large" />
                      :
                      <>
                      {btnstatus===2?
                       <Button type="button" label="Bid Submitting" size="large" />
                      :
                      <Button type="button" label="Bid Submited" size="large" />
                      }
                      </>
                      }
                      </>
                      }
                      
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
          <Card>
            <div className="actbet">Todays Bet</div>
            <Divider />
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Bet For</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">@Rate</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{row.game_name}</TableCell>
                      <TableCell align="center">{(row.amount).toFixed(2)}</TableCell>
                      <TableCell align="center">{row.rate}</TableCell>
                      <TableCell align="center">
                        {row.paid_off===0?"IN BET":"CREDITED"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
        </div>
      </div>
      <BidList logedin="1" />
    </div>
  );
};
