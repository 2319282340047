import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
export const EditUser = () => {
  let { memid } = useParams("memid");
  console.log(memid)
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/admin/getmember",
        {
          memid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        formik.setFieldValue("memberid", resp.data.user.memberid)
        formik.setFieldValue("password", resp.data.user.password)
        formik.setFieldValue("mobile", resp.data.user.mobile)
      }
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    
  });
  const formik = useFormik({
    initialValues: {
      memberid: "",
      mobile: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      //   setBtnStatus(2)
      const resp = await axios.post(
        url + "/admin/setmember",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Password Updated...",
        });
        // setBtnStatus(3)
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div style={{padding:"100px 30px"}}>
      <Toast ref={toast} />
      <div className="content p-5">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <Card title="Edit User" className="cust-bg">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group flex flex-column gap-3">
                    <div className="component flex-1">
                    <div>Member Id</div>
                      <InputText
                        type="text"
                        name="memberid"
                        placeholder="Member Id"
                        disabled
                        onChange={formik.handleChange}
                        value={formik.values.memberid}
                      />
                      {getFormErrorMessage("gameid")}
                    </div>
                    
                    <div className="component flex-1">
                    <div>Mobile</div>
                      <InputText
                        type="text"
                        name="mobile"
                        placeholder="Member Id"
                        disabled
                        onChange={formik.handleChange}
                        value={formik.values.mobile}
                      />
                      {getFormErrorMessage("mobile")}
                    </div>
                    <div className="component">
                      <div>Pasword</div>
                      <InputText
                        type="text"
                        name="password"
                        placeholder="Password"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      {getFormErrorMessage("password")}
                    </div>
                    <div className="component">
                        <Button type="submit" label="Confirm" />
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
