
import React from 'react'
import "./dailybetting.scss"
import { MorningBetSetting } from '../../../components/admin/dailybetsetting/MorningBetSetting'
import { EveningBetSetting } from '../../../components/admin/dailybetsetting/EveningBetSetting'
export const DailyBetting = () => {
  return (
    <div className='dailybetting'>
      <div className="grid">
      <div className="col-12 md:col-12 lg:col-6 left">
        <MorningBetSetting />
      </div>
      <div className="col-12 md:col-12 lg:col-6 right">
        <EveningBetSetting />
      </div>
      </div>
    </div>
  )
}
