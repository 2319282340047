import React, { useState, useRef, useEffect } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
export const MorningBetSetting = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({})
  useEffect(()=>{
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/admin/getactbid",{
          bidFor: "MORNING"
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if(resp.status===200){
        setData(resp.data.resp);
        formik.setFieldValue("betdate", resp.data.resp.bet_date)
        formik.setFieldValue("betstime", resp.data.resp.bet_start_time)
        formik.setFieldValue("betetime", resp.data.resp.bet_end_time)
      }
    }
    fetchData()
  },[])
  const validationSchema = Yup.object({
    betfor: Yup.string().required("Opps"),
    betdate: Yup.date("Invalid Email, Please Check!!!").required(
      "Field can not be empty."
    ),
    betstime: Yup.mixed("Invalid Email, Please Check!!!").required(
      "Field can not be empty."
    ),
    betetime: Yup.mixed("Invalid Email, Please Check!!!").required(
        "Field can not be empty."
      ),
  });
  const formik = useFormik({
    initialValues: {
      betfor: "MORNING",
      betdate: "",
      betstime: "",
      betetime: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      //setButtonDisabled(true);
      const resp = await axios.post(
        url + "/admin/setactivebet",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        // localStorage.setItem("damanmemid", resp.data.memberid);
        // localStorage.setItem("damanemail", resp.data.email);
        // Navigate("/user/");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div>
      <Card title="Morning Bet Setting">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form-group">
              <div className="component">
                <div>Bet Date</div>
                <InputText
                  type="date"
                  name="betdate"
                  placeholder="Email Address"
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.betdate}
                />
                {getFormErrorMessage("betdate")}
              </div>
              <div className="component">
                <div>Start Time</div>
                <InputText
                  type="time"
                  name="betstime"
                  placeholder="Start Time"
                  onChange={formik.handleChange}
                  value={formik.values.betstime}
                />
                {getFormErrorMessage("betstime")}
              </div>
              <div className="component">
                <div>End Time</div>
                <InputText
                  type="time"
                  name="betetime"
                  placeholder="End Time"
                  onChange={formik.handleChange}
                  value={formik.values.betetime}
                />
                {getFormErrorMessage("betetime")}
              </div>
              <div className="component">
                <Button type="submit" label="Submit" size="large" />
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Card>
    </div>
  );
};
