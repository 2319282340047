import React, { useState, useEffect } from "react";
import "./dashboardbid.scss";
import axios from "axios";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import moment from "moment";
import { Divider } from "primereact/divider";
import Countdown from "react-countdown";
export const DashBoardMorning = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  const [startTime, setStartTime] = useState("2025-10-14 00:00:00");
  const [endTime, setEndTime] = useState("2025-10-14 00:00:00");
  const [btnstatus, setBtnStatus] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getbiddatadata",
        {
          bidFor: "MORNING",
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.actbet);
        setStartTime(
          moment(resp.data.actbet.bet_start_at)
            .utcOffset(330)
            .format("YYYY-MM-DD HH:mm:ss")
        );
        setEndTime(
          moment(resp.data.actbet.bet_end_at)
            .utcOffset(330)
            .format("YYYY-MM-DD HH:mm:ss")
        );
      }
    };
    fetchData();
  }, []);
  return (
    <div className="dashboardbid">
      <Card>
        <div className="cont">
          <div className="head">Morning Bet</div>
          <div className="data">
            <div>Date</div>
            <div>{data.bet_date}</div>
          </div>
          <div className="data">
            <div>Start Time</div>
            <div>{data.bet_start_time}</div>
          </div>
          <div className="data">
            <div>End Time</div>
            <div>{data.bet_end_time}</div>
          </div>
          <div className="data">
            <div>Starts</div>
            <div>
              <Countdown date={startTime} onComplete={() => setBtnStatus(1)} />
            </div>
          </div>
          <div className="data">
            <div>Ends</div>
            <div>
              <Countdown date={endTime} onComplete={() => setBtnStatus(1)} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
